import React from "react"
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import Layout from '../components/Layout/Layout'
import Img from 'gatsby-image'
import SEO from "../components/seo"
import { getParagraph } from "./fragments/paragraphsHelper"

const Template = ({ data, location }) => {
  const article = data.nodeArticle
  const paragraphs = article.relationships.field_content.map(getParagraph)
  return (
    <Layout location={location} nid={article.drupal_internal__nid}>
      <SEO title={article.title} />
      <section className="container mx-auto px-8">
        <div className="page-title">
          {article.title}
        </div>
        {article.relationships.field_author?.name &&
          <div className="text-sm">
            by: {article.relationships.field_author.name}
          </div>
        }
        {article?.relationships?.field_image?.localFile &&
          <div
              style={{ width: article.field_image.width + 'px'}}
              className={"md:float-right ml-3 mb-2 " + (article.field_image.width > 400 ? "md:max-w-md" : "w-full md:max-w-sm")}
            >
            <Img fluid={
              article.relationships.field_image.localFile.childImageSharp.fluid
            } />
          </div>
        }
        <div className="mt-5" />
        {paragraphs}
      </section>
    </Layout>
  )
}

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Template;

export const query = graphql`
  query($articleId: String!) {
    nodeArticle(id: { eq: $articleId }) {
      id
      drupal_internal__nid
      title
      path {
        alias
      }
      field_image {
        width
      }
      relationships {
        field_author {
          name
        }
        field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 650, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_content {
          type: __typename
          ...ParagraphsContent
          ...ParagraphsImage
        }
      }
    }
  }`