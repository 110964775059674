import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

export const ParagraphsImage = ({ node }) => (
  <div
    className={"border mb-2 border-4 " + (node.imageFormat === 'left' ? 'float-left mr-3' : 'float-right ml-3')}
    style={{ width: `${node.image.width}px` }}>
    {node.relationships?.image &&
      <figure>
        <Img fluid={node.relationships.image.localFile.childImageSharp.fluid}
          alt={node.image.alt}/>
        {node.imageText &&
        <figcaption dangerouslySetInnerHTML={{ __html: node.imageText }}
          className="p-1 text-sm bg-gray-300 text-center"
        />}
    </figure>
  }
  </div>
);

export const fragment = graphql`
  fragment ParagraphsImage on paragraph__image {
    id
    image: field_paragraph_image {
      alt
      width
    }
    imageText: field_caption
    imageFormat: field_image_format
    relationships {
      image: field_paragraph_image {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
